/* eslint-disable no-console, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events*/
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import * as prismic from "@prismicio/client";
import { client as prismicClient } from "../../libs/prismic";
import Layout from "../../components/Layout";
import Loader from "../../components/Loader";
import Brand from "../../components/Brand";
import { Col, Row } from "react-bootstrap";
import { Table, Thead, Tbody, Tr, Th, Td } from "../../components/Table";
import AccountService from "../../services/AccountService";
import {
  getInvestCount,
  getInvestCost,
  getGain,
  getInvestChartData,
  getOpal,
  getCurrentPrice,
} from "../../utils";
import "../../styles/pages/portfolio.scss";
import ChartSection from "../../components/Portfolio/ChartSection";
import TransactionSection from "../../components/Portfolio/TransactionSection";
import InvestorSection from "../../components/Portfolio/InvestorSection";
import OpalSection from "../../components/Portfolio/OpalSection";
import { DateTime } from "luxon";

const accountService = new AccountService();

const footer = (tooltipItems) => {
  if (tooltipItems?.[0]?.parsed.y === 0) {
    return null
  } else {
    return new Date(tooltipItems?.[0]?.parsed.x).toLocaleDateString("en-us", {
      year: "numeric",
      month: "long",
      day: "numeric",
    });
  }
};

const customPointCanvas = function (context, options) {
  console.log('cpt', context.raw?.ptype);
  if (context.raw?.ptype != 'TodayPrice') {
    return 'circle';
  }
  const radius = options.pointRadius || 5;
  const cvs = document.createElement('canvas');
  cvs.height = 2 * radius;
  cvs.width = 2 * radius;

  const ctx = cvs.getContext('2d');

  // Coordinates for the center of the canvas, not the original `x` and `y`
  const centerX = radius;
  const centerY = radius;

  // Main Circle
  ctx.beginPath();
  ctx.arc(centerX, centerY, radius, 0, Math.PI * 2);
  ctx.closePath();
  ctx.fillStyle = options.pointBackgroundColor;
  ctx.fill();
  ctx.strokeStyle = options.borderColor;
  ctx.lineWidth = options.borderWidth;
  ctx.stroke();

  // White Arc
  ctx.beginPath();
  ctx.arc(centerX, centerY, radius / 2 + (options.borderWidth / 2), 0, Math.PI * 2);
  ctx.closePath();
  ctx.strokeStyle = options.borderColor;
  ctx.lineWidth = options.borderWidth * 1.5;
  ctx.stroke();

  return cvs;
}

const Portfolio = ({
  slug,
  location,
}) => {
  const [isLoading, setLoading] = useState(false);
  const [account, setAccount] = useState(null);
  const [count, setCount] = useState(null);
  const [cost, setCost] = useState(null);
  const [brandDoc, setBrandDoc] = useState();
  const [opals, setOpals] = useState([]);
  const [keyMemberNames, setKeyMemberNames] = useState([]);
  const [monthCost, setMonthCost] = useState(null);
  const [currentPrices, setCurrentPrices] = useState({});
  const [blogItems, setBlogItems] = useState();
  const [chartData, setChartData] = useState({
    datasets: [
      {
        label: "",
        data: [],
        borderColor: "#d9d9d9",
        pointBorderColor: "#fff",
        pointBackgroundColor: function (ctx) {
          console.log('ctx:', ctx);
          if (ctx.raw?.ptype === "Issued") {
            return "#1da775";
          } else if (ctx.raw?.ptype === "Purchased") {
            return "#ffdf75";
          }
          return "#39c";
        },
        pointBorderWidth: 2,
        pointHoverRadius: 12,
        pointRadius: 12,
        pointHitRadius: 10,
        borderWidth: 8,
        pointStyle: customPointCanvas,
      },
      {
        label: "",
        data: [],
        borderColor: "#d6d6d634",
        pointBorderWidth: 0,
        pointHoverRadius: 0,
        pointRadius: 0,
        pointHitRadius: 0,
        borderWidth: 0,
      }
    ],
  })

  const [options, setOptions] = useState({
    responsive: true,
    clip: false,
    maintainAspectRatio: false,
    vlines: Array(6).fill(0),
    scales: {
      x: {
        beginAtZero: true,
        type: "time",
        offset: false,
        time: {
          unit: "week",
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          color: "#000",
        },
      },
      y: {
        beginAtZero: true,
        display: false,
        grid: {
          display: false,
        },
        ticks: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        backgroundColor: "#39c",
        displayColors: false,
        titleColor: "#3399cc00",
        titleFont: {
          size: "1px",
        },
        footerFont: {
          weight: "normal",
        },
        bodyFont: {
          weight: "bold",
        },
        caretPadding: 20,
        titleMarginBottom: 0,
        callbacks: {
          footer: footer,
          label: function (context) {
            let label = "";
            if (context.parsed.y !== null) {
              label += new Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(context.parsed.y);
            }

            return label;
          },
        },
      },
      verticalLines: {
        enable: true,
      },
    }
  })

  const [selectedPeriod, setSelectedPeriod] = useState('year');

  const parentAccounts = useSelector(
    (state) => state?.accounts?.parentAccounts
  );

  const refreshKey = useSelector(
    (state) => state?.dialog?.refreshKey
  );

  const user = useSelector(
    (state) => state?.app?.profile
  );
  const params = new URLSearchParams(location.search);
  const brandUid = params.get("brandUid");
  const transactionId = params.get("tid");
  const fromPage = params.get("fromPage");

  const ria = parentAccounts?.[0]?.type === 'ria'

  const periodHandler = (_period) => {

    const newOptions = {
      ...options
    }

    let fromDate = new Date();
    let today = new Date();

    let vlines = []
    switch (_period) {
      case 'week':
        newOptions.scales.x.time.unit = 'day';
        newOptions.scales.x.min = DateTime.now().minus({ days: 6 }).toISODate();
        newOptions.scales.x.max = DateTime.now().toISODate();
        vlines = Array(6).fill(0)
        break;
      case 'month':
        newOptions.scales.x.time.unit = 'week';
        newOptions.scales.x.min = DateTime.now().minus({ months: 1 }).toISODate();
        newOptions.scales.x.max = DateTime.now().toISODate();
        vlines = Array(3).fill(0)
        break;
      case 'quarter':
        newOptions.scales.x.time.unit = 'month'
        newOptions.scales.x.min = DateTime.now().minus({ months: 3 }).toISODate();
        newOptions.scales.x.max = DateTime.now().toISODate();
        vlines = Array(2).fill(0)
        break;
      case 'year':
        newOptions.scales.x.time.unit = 'month'
        newOptions.scales.x.min = DateTime.now().minus({ years: 1 }).toISODate();
        newOptions.scales.x.max = DateTime.now().toISODate();
        vlines = Array(11).fill(0)
        break;
      case 'ytd':
        newOptions.scales.x.time.unit = 'month'
        newOptions.scales.x.min = DateTime.now().startOf('year').toISODate();
        newOptions.scales.x.max = DateTime.now().toISODate();
        const monthsTillNow = DateTime.now().month - 1;
        vlines = Array(monthsTillNow).fill(0);
        break;
      case 'all':
        newOptions.scales.x.time.unit = 'year'
        fromDate.setFullYear(2020, 0, 1);
        fromDate.setHours(0, 0, 0, 0);
        newOptions.scales.x.min = DateTime.local(2020, 1, 1).toISODate();
        newOptions.scales.x.max = DateTime.now().toISODate();
        vlines = Array(today.getFullYear() + 2 - fromDate.getFullYear()).fill(0)
        break;
      default:
        break;
    }
    newOptions.vlines = vlines;
    setOptions(newOptions);
    setSelectedPeriod(_period);
  };

  let blogData;

  useEffect(() => {
    async function fetchData(slug) {
      try {
        setLoading(true);
        const _account = await accountService.getAccount(slug, true);
        setAccount(_account);
        const _count = getInvestCount(_account, ria, false);
        const _cost = getInvestCost(_account, ria, currentPrices, false);
        const { monthCost: _monthCost } = await getGain(_account, ria, opals, false);

        setCount(_count);
        setCost(_cost);
        console.log('GOO_setcost', cost);
        setMonthCost(_monthCost);
        if (fromPage === 'invest-trade') {
          setTimeout(() => document.getElementById("pending-table").scrollIntoView(), 500);
        }
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }
    if (slug && parentAccounts.length > 0) {
      fetchData(slug);
    }
  }, [slug, parentAccounts, refreshKey, fromPage, ria, currentPrices]);

  useEffect(async () => {
    if (brandDoc?.data?.brand_key_members) {
      setKeyMemberNames(brandDoc?.data?.brand_key_members.map((item) => item?.text))
    }
  }, [brandDoc]);
  
  useEffect(() => {
    async function fetchData() {
      const brandResponse = await prismicClient().get({
        predicates: prismic.predicates.at(
          "document.type",
          "opal_fund_partners"
        ),
        orderings: {
          field: "document.first_publication_date",
          direction: "desc",
        },
      });
      let _opals = [];
      let _currentPrices = {};
      for (const brand of brandResponse?.results) {
        for (const opal of brand.data?.opals) {
          _opals.push({ brand, opal });
          let key = `${brand.uid}-${opal.opal_name}`;
          _currentPrices[key] = await getCurrentPrice(opal);
        }
      };
      setOpals(_opals);
      setCurrentPrices(_currentPrices);
      if (brandUid) {
        setBrandDoc(brandResponse.results.find(el => el.uid === brandUid))
      }
    }

    fetchData();
  }, [brandUid]);

  useEffect(() => {
    async function fetchAndSetData() {
      const blogPostDocResponse = await prismicClient().getAllByType("blog_post");
      const pressMediaDocResponse = await prismicClient().getAllByType("press_media_post");
      const docDataValue = [...blogPostDocResponse, ...pressMediaDocResponse];

      setDocData(docDataValue);
    }

    if (brandDoc) {
      fetchAndSetData();
    }
  }, [brandDoc])

  useEffect(() => {
    if (account && selectedPeriod) {
      getInvestData();
    }
  }, [account, selectedPeriod, options, brandUid, opals]);
  const setDocData = (docData) => {
    let docDataMatched;

    docDataMatched = docData.filter((item) => item?.data?.post_to_opal_fund_partner.includes(brandDoc?.data?.brand_image_title[0].text));
    blogData = docDataMatched.map((item) => ({
      "title" : item?.data?.title[0]?.text,
      "date": new Date(item?.data?.date),
      "uid": item?.uid,
      "type": item?.type
    }));

    blogData.sort((a, b) => b.date - a.date);
    blogData.splice(3);
    setBlogItems(blogData);
  }

  const getInvestData = async () => {
    const { data, initData } = await getInvestChartData(account, selectedPeriod, brandUid, opals);
    const _data = {
      ...chartData,
      datasets: [{
        ...chartData.datasets[0],
        data: data
      },
      {
        ...chartData.datasets[1],
        data: initData
      }]
    }
    console.log('DDD__account', account);
    console.log('DDD__selectedPeriod', selectedPeriod);
    console.log('DDD__brandUid', brandUid);
    console.log('DDD__opals', opals);
    console.log('DDD__data', data);
    console.log('DDD__initData', initData);
    setChartData(_data)
  }

  let completedTransaction = [];

  account?.transactions.forEach(el => {
    if (el.step === 'dialog_submitted' && el.status === 'paid') {
      const key = `${el['accountId']}-${el['opal_name']}-${el['brand_uid']}`;
      const indexOfelment = completedTransaction.findIndex(t => t.key === key)

      if (indexOfelment !== -1) {
        completedTransaction[indexOfelment].units += el.units;
      } else {
        completedTransaction.push({
          ...el,
          key,
          units: el.units
        })
      }
    }
  })

  let pendingTransaction = account?.transactions.filter(el => el.status !== 'paid' && el.step !== 'dialog_canceled') || [];

  let selectedTransaction;
  if (transactionId) {
    completedTransaction = completedTransaction?.filter(el => el._id === transactionId)
    pendingTransaction = pendingTransaction?.filter(el => el._id === transactionId);
    selectedTransaction = completedTransaction.length === 1 ? completedTransaction[0] : pendingTransaction[0]
  }

  completedTransaction.sort(function (a, b) { return new Date(b.modified) - new Date(a.modified) });
  pendingTransaction.sort(function (a, b) { return new Date(b.modified) - new Date(a.modified) });

  useEffect(() => {
    periodHandler(selectedPeriod);
  }, []);

  return (!isLoading && account) ? (
    <Layout>
      <div className="portfolio">
        <Row className="justify-content-center mx-auto mw-1440 ">

          <InvestorSection
            ria={ria}
            account={account}
            completedTransaction={completedTransaction}
            pendingTransaction={pendingTransaction}
            user={user}
            parentAccounts={parentAccounts}
            cost={cost}
            count={count}
            monthCost={monthCost}
            transactionId={transactionId}
            slug={slug}
          />
          <ChartSection selectedPeriod={selectedPeriod} periodHandler={periodHandler} options={options} chartData={chartData} />

          <TransactionSection
            transactionId={transactionId}
            account={account}
            selectedPeriod={selectedPeriod}
            slug={slug}
            ria={ria}
            pendingTransaction={pendingTransaction}
            completedTransaction={completedTransaction}
            opals={opals}
          />
          {brandUid && <Col md={12} className="d-md-block mt-40">
            <Table>
              <Thead>
                <Tr>
                  <Th style={{ width: '100%' }}>Fund Manager <span className="linkable">(?)</span></Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td>
                    <Brand
                      image={brandDoc && brandDoc?.data?.brand_image?.url}
                      heading1={brandDoc && brandDoc?.data?.brand_heading_1[0]?.text}
                      description={brandDoc && brandDoc?.data?.brand_description_1[0]?.text}
                      imageTitle={brandDoc && brandDoc?.data?.brand_image_title[0]?.text}
                      amount={brandDoc && brandDoc?.data?.brand_amount[0]?.text}
                      keyMembers={brandDoc && keyMemberNames}
                      address={brandDoc && brandDoc?.data?.brand_address[0]?.text}
                      blogItems={blogItems}
                      className="brand"
                    />
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Col>}
          {/* {(brandUid && brandDoc) && <OpalSection account={account} brandsDoc={brandDoc} opal={brandDoc.data?.opals.filter(el => el.opal_name === selectedTransaction.opal_name)[0]} />} */}
        </Row>
      </div>
    </Layout>
  ) : (
    <Loader />
  );
};

export default Portfolio;
