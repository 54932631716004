import React, { useEffect, useState } from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  TimeScale,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import { Line } from "react-chartjs-2";
import { getDataByUID } from "../../libs/prismic";
import {
  getPriceChartData,
  numberWithCommas,
} from "../../utils";
import "chartjs-adapter-luxon";
import { DateTime } from "luxon";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  TimeScale,
  Filler,
  Title,
  Tooltip,
  Legend
);

const MiniChart = React.memo(({ transaction, period = 'year' }) => {

  const [opal, setOpal] = useState()
  const [performanceCost, setPerformanceCost] = useState()
  const [performancePercent, setPerformancePercent] = useState()
  const [options, setOptions] = useState({
    responsive: true,
    clip: false,
    maintainAspectRatio: false,
    scales: {
      x: {
        beginAtZero: true,
        type: "time",
        offset: false,
        time: {
          unit: "month",
        },
        border: {
          display: false,
        },
        grid: {
          display: false,
        },
        ticks: {
          color: "#000",
        },
        display: false,
      },
      y: {
        beginAtZero: true,
        display: false,
      },
    },
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enable: false,
      },
      filler: {
        propagate: true,
      },
    }
  })

  const [chartData, setChartData] = useState({
    datasets: [
      {
        label: "",
        data: [],
        borderColor: "#92C1DD",
        backgroundColor: "#92C1DD",
        fill: "start",
        pointBorderColor: "#92C1DD",
        pointBackgroundColor: "#92C1DD",
        pointBorderWidth: 0,
        pointHoverRadius: 0,
        pointRadius: 0,
        pointHitRadius: 0,
        borderWidth: 0,
      },
      {
        label: "",
        data: [],
        borderColor: "#d6d6d634",
        pointBorderWidth: 0,
        pointHoverRadius: 0,
        pointRadius: 0,
        pointHitRadius: 0,
        borderWidth: 0,
      }
    ],
  })

  useEffect(() => {
    if (transaction) {
      getPriceReport(transaction)
    }
  }, [transaction])

  useEffect(() => {
    if (opal) {
      getPriceData(opal)
    }
  }, [opal, period])

  const getPriceReport = async (t) => {
    if (t.brand_uid) {
      const response = await getDataByUID("opal_fund_partners", t.brand_uid);
      const _opal = response.data.opals.filter(el => el.opal_name === t.opal_name)?.[0];
      setOpal(_opal)
    }
  }

  const getPriceData = async (opal) => {
    const { chartdata, percent, cost } = await getPriceChartData(transaction, opal, period);

    setPerformanceCost(cost);
    setPerformancePercent(percent)

    let data = chartdata.data;
    let initData = chartdata.initData;
    const _data = {
      ...chartData,
      datasets: [
        {
          ...chartData.datasets[0],
          data: data
        },
        {
          ...chartData.datasets[1],
          data: initData
        }
      ],
    }
    setChartData(_data)

    const newOptions = {
      ...options
    }
    switch (period) {
      case 'week':
        newOptions.scales.x.time.unit = 'day'
        newOptions.scales.x.min = DateTime.now().minus({ days: 6 }).toISODate();
        newOptions.scales.x.max = DateTime.now().toISODate();
        break;
      case 'month':
        newOptions.scales.x.time.unit = 'week'
        newOptions.scales.x.min = DateTime.now().minus({ months: 1 }).toISODate();
        newOptions.scales.x.max = DateTime.now().toISODate();
        break;
      case 'quarter':
        newOptions.scales.x.time.unit = 'month'
        newOptions.scales.x.min = DateTime.now().minus({ months: 3 }).toISODate();
        newOptions.scales.x.max = DateTime.now().toISODate();
        break;
      case 'ytd':
        newOptions.scales.x.time.unit = 'month'
        newOptions.scales.x.min = DateTime.now().startOf('year').toISODate();
        newOptions.scales.x.max = DateTime.now().toISODate();
        break;
      case 'year':
        newOptions.scales.x.time.unit = 'month'
        newOptions.scales.x.min = DateTime.now().minus({ years: 1 }).toISODate();
        newOptions.scales.x.max = DateTime.now().toISODate();
        break;
      case 'all':
        newOptions.scales.x.time.unit = 'year'
        newOptions.scales.x.min = DateTime.local(2020, 1, 1).toISODate();
        newOptions.scales.x.max = DateTime.now().toISODate();
        break;
      default:
        break;
    }
    setOptions(newOptions);
  }
  return (
    <div className="performance-div"><div className="value-div">
      <div>
        {`${performanceCost >= 0 ? '+' : '-'} $${numberWithCommas(Math.abs(performanceCost), false)}`}
      </div>
      <div style={{ color: performancePercent === 0 ? '#000000' : (performancePercent > 0 ? '#1DA775' : '#F06158') }}>
        {`${performancePercent >= 0 ? '+' : '-'} ${Math.abs(performancePercent)}%`}
      </div>
    </div>
      <div className="mini-chart-div">
        {(opal) && <Line options={options} data={chartData} />}
      </div>
    </div>
  );
})

export default MiniChart;
